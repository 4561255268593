.rc-dialog-title {
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700
}

.rc-dialog-content {
  position: relative;
  background-color: #fff;
  border: none;
  border-radius: 6px;
  background-clip: padding-box
}

.rc-dialog-close:disabled {
  pointer-events: none
}

.rc-dialog-header {
  padding: 30px 30px 15px;
  border-radius: 5px 5px 0 0;
  background: #fff;
  color: #666;
}

.rc-dialog-body {
  padding: 0 30px 30px;
}

.rc-dialog-footer {
  border-top: 1px solid #e9e9e9;
  padding: 10px 20px;
  text-align: right;
  border-radius: 0 0 5px 5px
}

.rc-dialog-zoom-enter,.rc-dialog-zoom-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.08,.82,.17,1);
  animation-play-state: paused
}

.rc-dialog-zoom-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.6,.04,.98,.34);
  animation-play-state: paused
}

.rc-dialog-zoom-enter.rc-dialog-zoom-enter-active,.rc-dialog-zoom-appear.rc-dialog-zoom-appear-active {
  animation-name: rcDialogZoomIn;
  animation-play-state: running
}

.rc-dialog-zoom-leave.rc-dialog-zoom-leave-active {
  animation-name: rcDialogZoomOut;
  animation-play-state: running
}

@keyframes rcDialogZoomIn {
  0% {
      opacity: 0;
      transform: scale(0)
  }

  to {
      opacity: 1;
      transform: scale(1)
  }
}

@keyframes rcDialogZoomOut {
  0% {
      transform: scale(1)
  }

  to {
      opacity: 0;
      transform: scale(0)
  }
}

@media (min-width: 768px) {
  .rc-dialog {
      width:600px;
      margin: 30px auto
  }
}

.rc-dialog {
  position: relative;
  width: auto;
  margin: 10px
}

.rc-dialog-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0
}

.rc-dialog-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px #00000080;
  background-clip: padding-box;
  outline: 0
}

.rc-dialog-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: 21px;
  position: absolute;
  right: 20px;
  top: 12px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
  text-decoration: none
}

.rc-dialog-close-x:after {
  content: "\d7"
}

.rc-dialog-close:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  text-decoration: none
}

.rc-dialog-title {
  margin: 0;
  line-height: 1.42857143
}

.rc-dialog-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5
}

.rc-dialog-footer .btn+.btn {
  margin-left: 5px;
  margin-bottom: 0
}

.rc-dialog-footer .btn-group .btn+.btn {
  margin-left: -1px
}

.rc-dialog-footer .btn-block+.btn-block {
  margin-left: 0
}

@media (min-width: 768px) {
  .rc-dialog {
      width:600px;
      margin: 30px auto
  }

  .rc-dialog-content {
      box-shadow: 0 5px 15px #00000080
  }
}

.rc-dialog-slide-fade-enter,.rc-dialog-slide-fade-appear {
  transform: translateY(-25%)
}

.rc-dialog-slide-fade-enter,.rc-dialog-slide-fade-appear,.rc-dialog-slide-fade-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
  animation-play-state: paused
}

.rc-dialog-slide-fade-enter.rc-dialog-slide-fade-enter-active,.rc-dialog-slide-fade-appear.rc-dialog-slide-fade-appear-active {
  animation-name: rcDialogSlideFadeIn;
  animation-play-state: running
}

.rc-dialog-slide-fade-leave.rc-dialog-slide-fade-leave-active {
  animation-name: rcDialogSlideFadeOut;
  animation-play-state: running
}

@keyframes rcDialogSlideFadeIn {
  0% {
      transform: translateY(-25%)
  }

  to {
      transform: translate(0)
  }
}

@keyframes rcDialogSlideFadeOut {
  0% {
      transform: translate(0)
  }

  to {
      transform: translateY(-25%)
  }
}

.rc-dialog-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: #37373799;
  height: 100%;
  filter: alpha(opacity=50);
  z-index: 1050
}

.rc-dialog-mask-hidden {
  display: none
}

.rc-dialog-fade-enter,.rc-dialog-fade-appear {
  opacity: 0;
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.55,0,.55,.2);
  animation-play-state: paused
}

.rc-dialog-fade-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(.55,0,.55,.2);
  animation-play-state: paused
}

.rc-dialog-fade-enter.rc-dialog-fade-enter-active,.rc-dialog-fade-appear.rc-dialog-fade-appear-active {
  animation-name: rcDialogFadeIn;
  animation-play-state: running
}

.rc-dialog-fade-leave.rc-dialog-fade-leave-active {
  animation-name: rcDialogFadeOut;
  animation-play-state: running
}

@keyframes rcDialogFadeIn {
  0% {
      opacity: 0
  }

  to {
      opacity: 1
  }
}

@keyframes rcDialogFadeOut {
  0% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}
