
/* 隐藏滚动条 */
::-webkit-scrollbar {
  background-color: transparent;
  width: 0
}

/* 隐藏滚动条的轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
  width: 0
}

/* 隐藏滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: transparent;
  width: 0
}

.scroll-bar-visible {
  /* 隐藏滚动条 */
  &::-webkit-scrollbar {
    position: absolute;
    left: 2px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 3px;
  }

  /* 隐藏滚动条的轨道 */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    width: 3px;
  }

  /* 隐藏滚动条的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(115, 115, 115, 0.3);
    border-radius: 3px;
    width: 3px;
    // background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(115, 115, 115, 0.5);
  }
}