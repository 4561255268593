
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './vars.less';
@import './default.less';
@import './scroll-bar.less';

@import './full-page.less';
@import './clear-default.less';

@import './wechat.less';
@import './dialog.less';
