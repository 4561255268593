.login-container {
  position: relative;
  display: flex;
  width: 1200px;
  height: 720px;
  overflow: hidden;
  margin: auto;

  .login-desc {
    position: relative;
    width: 720px;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0px 20px;

    .desc-title-wrap {
      .desc-title {
        position: relative;
        font-family: AlibabaPuHuiTi;
        color: white;
        font-size: 46px;
        font-weight: 900;
        line-height: 64px;
      }


      .desc-content {
        position: relative;
        z-index: 9;
        font-size: 16px;
        color: white;
      }
    }
  }

  .login-main {
    position: relative;
    padding-top: 72px;
    padding: 72px 40px 0 40px;
    border-radius: 8px;
    background-color: #fff;
    width: 480px;
    min-width: 480px;

    .title {
      color: #272c33;
      text-align: center;
      font-family: PingFang SC;
      font-size: 32px;
      font-weight: 500;
      line-height: 32px;
      margin: 20px 0;
    }

    .title-tab {
      margin: 20px 0px;
    }

    .wechat-content {
      text-align: center;
    }

    .mobile {
      padding-left: 84px;
    }

    .code {
      padding-right: 20px;
    }

    .signup-tip {
      color: #00000066;
      font-size: 14px;
    }

    .mobile-wrap {
      position: relative;
      margin-bottom: 20px;

      .area-code-container {
        position: absolute;
        top: 0;
        left: 20px;
        width: 48px;
        height: 48px;
        cursor: pointer;
        z-index: 99;

        .area-code {
          color: #676d78;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
        }

        .arrow {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .wrong-phone {
        position: absolute;
        right: 0px;
        color: #fe4900;
        font-size: 14px;
      }
    }

    .input-wrap {
      position: relative;
    }

    .verify-input2 {
      margin: 20px 0;
    }

    .reVerifyTip {
      cursor: pointer;
      pointer-events: none;
      user-select: none;
      display: contents;

      &[data-active='true'] {
        pointer-events: all;
        color: var(--dauyan_v2_base_blue_1);
      }
    }

    .back-btn {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 44px;
      height: 44px;
      background-color: var(--dauyan_v2_base_blue_2);
      text-align: center;
      line-height: 44px;
      border-radius: 10px;
      font-size: 20px;
      user-select: none;
      cursor: pointer;
    }

    .code-wrap {
      position: relative;
      margin: 20px 0;

      .forget-password {
        position: absolute;
        color: var(--dauyan_v2_base_blue_1);
        right: 0px;
        bottom: 0;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
      }

      .cannot-login {
        position: absolute;
        color: var(--dauyan_v2_base_blue_1);
        left: 0;
        bottom: 0;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;

      }
    }

    .password-with-info {
      margin-bottom: 20px;
    }

    .password2 {
      margin-bottom: 0;
    }

    .qrcode-img {
      width: 146px;
      height: 146px;
      margin: 20px auto;
    }

    .status-container {
      width: 290px;
      display: flex;
      justify-content: start;
      align-items: center;
      margin: 10px 0px;
      cursor: pointer;

      .checkdesc {
        color: #676d78;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .wechat-status-container {
      width: 146px;
      display: flex;
      justify-content: start;
      align-items: center;
      margin: 0 auto 34px;

      .checkdesc {
        color: #676d78;
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .protocol-container {
      display: flex;
      justify-content: start;
      align-items: flex-start;

      .protocol-content {
        flex: 1;
        color: rgba(122, 127, 137, 0.4);
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.052px;

        .protocol-mark {
          color: rgba(103, 109, 120, 0.8);
        }
      }
    }
  }
}

.btn {
  height: 48px;
  border-radius: 8px;
  background-color: var(--dauyan_v2_base_blue_1);
  text-align: center;
  line-height: 48px;
  color: #fff;
  text-align: center;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0;
  cursor: pointer;
}

.input-box {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 48px;
  padding: 12px 20px;
  border: 1px solid rgba(122, 127, 137, 0.2);
  border-radius: 8px;
  outline: none;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 1px;
}

.input-box:focus {
  border-color: var(--dauyan_v2_base_blue_1);
}

.input-box-error {
  border-color: #d93535;
}

.error-tips {
  position: absolute;
  left: 60px;
  bottom: -18px;
  // height: 17px;
  color: #ef4444;
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
}

input::-webkit-input-placeholder {
  color: rgba(122, 127, 137, 0.4);
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.checkspan {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid rgba(122, 127, 137, 0.2);
  background-color: #fff;
  margin-right: 8px;
  cursor: pointer;
  appearance: none;
}

.checkspan:checked {
  appearance: auto;
  background-color: var(--dauyan_v2_base_blue_1);
}

.change-login-type {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;

  .tips {
    position: absolute;
    left: -89px;
    top: 50%;
    transform: translateY(-50%);
    width: 89px;
    height: 28px;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: rgba(122, 127, 137, 0.1);
    color: #676d78;
    text-align: center;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 28px;

    &::after {
      content: '';
      position: absolute;
      right: -12px;
      top: 50%;
      transform: translateY(-50%);
      border: 6px solid transparent;
      border-left-color: rgba(122, 127, 137, 0.1);
    }
  }
}

.login-bg-color {
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.login-bg-stu {
  position: absolute;
  bottom: 0;
  right: -22px;
  width: 261px;
  height: 207px;
}

// 覆盖微信二维码样式
#qrcode {
  iframe {
    width: 146px;
    height: 146px;
    margin: 0;
    padding: 0;
  }
}

// 转base64覆盖微信二维码样式
.impowerBox .info,
.impowerBox .title {
  display: none;
}

.impowerBox .qrcode {
  width: 146px !important;
  margin: 0;
  border: 0;
  padding: 0;
}

.success-content {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .success-icon {
    width: 72px;
    height: 72px;
    background-color: var(--dauyan_v2_base_blue_2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reset-suc-tip {
    margin: 20px 0;
  }
}