.tab {
  display: flex;
  height: 57px;
}
.tab-con {
  display: flex;
  background-color: #eeeeee;
  padding: 6px;
  border-radius: 10px;
  position: relative;
}
.tab-item {
  color: #272c33;
  text-align: center;
  font-family: PingFang SC;
  font-size: 14px;
  height: 100%;
  font-weight: 400;
  line-height: 45px;
  transition: all 0.5s;
  cursor: pointer;
  color: #000000;
  position: relative;
  padding: 0px 10px;
  border-radius: 10px;
}

.active {
  color: #000000;
  background-color: #ffffff;
  position: absolute;
  top: 6px;
  height: 45px;
  width: 76px;
  border-radius: 10px;
  transition: all 0.3s;
}
