* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // color: var(--dauyan_font_color_1);
}

a {
  text-decoration: none;
  color: var(--dauyan_font_color_1);
}

a:hover {
  color: var(--dauyan_font_color_1);
}

ul,
ol {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* 火狐浏览器 */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
  height: 30px;
  line-height: 30px;
}
