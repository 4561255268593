:root {
  // 主题色
  --dauyan_color_1: #14A17F;
  --dauyan_color_2: var(--dauyan_v2_base_blue_1);
  --dauyan_color_3: rgba(74, 158, 129, 1);
  --dauyan_color_4: #FD8936;
  --dauyan_color_5: #DC2626;
  --dauyan_color_6: rgba(74, 158, 129, 0.14);


  --dauyan_font_color_1: #1C1F25;
  --dauyan_font_color_2: rgba(103, 109, 120, 1);
  --dauyan_font_color_3: #676D78;
  --dauyan_font_color_4: #272C33;

  --dauyan_border_color_1: rgba(122, 127, 137, 0.40);
  --dauyan_border_color_2: rgba(74, 158, 129, 0.10);
  --dauyan_border_color_3: rgb(255 255 255 / 0%);

  --dauyan_basic_button_b_color: rgba(74, 158, 129, 0.10);
  --dauyan_basic_button_o_color: rgb(255 255 255 / 0%);
  --dauyan_basic_border_color: #E4E4E5;
  --dauyan_basic_background_hover: #e4e4e52b;

  --dauyan_content_color: #676D78;
  --dauyan_ai_grandient: linear-gradient(104.68deg, #4F65F6 5.08%, #854EF6 58.98%, #C333F6 98.25%);
  --dauyan_ai_color_1: #7761FF;

  // 布局
  --dauyan_scene_min_width: 1200px;

  // 文字
  --dauyan_standard_white_color: #ffffff;
  --dauyan_basic_color: #1C1F25;
  --dauyan_basic_font_size: 16px;
  --dauyan_basic_font_size-12: 12px;
  --dauyan_basic_font_size-28: 28px;
  --dauyan_basic_font-family: 'PingFang SC';
  --dauyan_basic_font-family-number: 'Rubik';
  --dauyan_basic_font-style: normal;

  --dauyan_basic_font-weight-400: 400;
  --dauyan_basic_font-weight: 500;
  --dauyan_basic_font-weight-600: 600;


  --dauyan_v2_base_blue_1: #1D77FF;
  --dauyan_v2_base_blue_2: #F1F6FF;
}