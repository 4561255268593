.modal {
  width: 100%;
  height: 100%;
}

.bg {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 0px;
  top: 0px;
}

.logo {
  position: absolute;
  top: 24px;
  left: 32px;
}

.mesh {
  width: 100%;
  height: 100%;
  background-color: #00000070;
}